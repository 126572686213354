import React, { useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-scroll";
import logo from "../assets/logo.svg";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const handleClose = () => setNav(false);

  return (
    <div className="w-screen h-[80px] z-10 bg-zinc-200 fixed drop-shadow-lg border-b-2 border-zinc-300">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <div className="w-16 h-20 relative overflow-hidden ml-2">
            <img
              className="w-full h-full object-cover opacity-100" // Adjusted opacity
              src={logo}
              alt="/"
            />
          </div>
          <ul className="hidden md:flex">
            <li>
              <Link
                to="home"
                smooth={true}
                duration={500}
                className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid  hover:border-[#350905]"

              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="Services"
                smooth={true}
                offset={-200}
                duration={500}
                className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-[#350905]"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="support"
                smooth={true}
                offset={-50}
                duration={500}
                className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-[#350905]"
              >
                Products
              </Link>
            </li>
            <li>
              <Link
                to="Contact"
                smooth={true}
                offset={-50}
                duration={500}
                className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-[#350905]"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden md:flex pr-4">
        </div>
        <div className="md:hidden mr-4" onClick={handleClick}>
          {!nav ? <MenuIcon className="w-5" /> : <XIcon className="w-5" />}
        </div>
      </div>
      <ul className={!nav ? "hidden" : "absolute bg-zinc-200 w-full px-8 border-b-2 border-zinc-300"}>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="home"
            smooth={true}
            duration={500}
            className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-indigo-500"
          >
            Home
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="Services"
            smooth={true}
            offset={-200}
            duration={500}
// Replace "indigo" with "#350905" in the class names
className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-#350905"
          >
            Services
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="support"
            smooth={true}
            offset={-50}
            duration={500}
// Replace "indigo" with "#350905" in the class names
className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-#350905"
          >
            Products
          </Link>
        </li>
        
        <li className="border-b-2 border-zinc-300 w-full">
          <Link
            onClick={handleClose}
            to="Contact"  // Change the target to "Contact"
            smooth={true}
            offset={-50}
            duration={500}
            className="cursor-pointer hover:pb-3 hover:border-b-[3px] hover:border-solid hover:border-#350905"
          >
            Contact_Us
          </Link>
        </li>
        
      </ul>
    </div>
  );
};

export default Navbar;