import React from "react";
import {
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaTwitch,
} from "react-icons/fa";

const Contact = () => {
  return (
    <div name="Contact">
      <div className="w-full mt-24 bg-[#350905] text-white py-8 px-2">
        <div className="max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8">
          <div>
            <h6 className="font-bold uppercase pt-2"> Solutions</h6>
            <ul>
              <li className="py-1">🌟 Precision Motion Controls:</li>
              <li className="py-1">⚙️ Automation Excellence:</li>
              <li className="py-1">🔬 Biomedical Solutions</li>
              <li className="py-1">🖥️ PLC Programming and Retrofitting</li>
              <li className="py-1">🔧 Specialized Machines:</li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold uppercase pt-2">Contact us</h6>
            <ul>
              <li className="py-1">+91-7876274221</li>
              <li className="py-1">+91-8894855061</li>
              <li className="py-1">+91-6283706588</li>
            </ul>
            <h6 className="font-bold uppercase pt-2"> Emails</h6>
            <ul>
              <li className="py-1">INFOKANNANSOLUTIONS@gmail.com</li>
              <li className="py-1">KANNANAUTOMATIONANDSOLUTIONS@gmail.com</li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold uppercase pt-2"> Location</h6>
            <ul>
              <li className="py-1">SHOP NO-2, KHASRA 267/13 NEAR GAS PLANT INDUSTRIAL AREA BAROTIWALA H.P 174103</li>
            </ul>
          </div>
          <div className="col-span-2 pt-8 sm: mr-2px md:pt-2 md:col-start-2 lg:col-start-3 xl:col-start-4">
            <p className="font-bold uppercase"></p>
            <iframe
              title="Map of Kannan Automation and Solutions"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3422.5753008911743!2d76.85863787538106!3d30.92649317449136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f8b4a1ffd622b%3A0xbbfbc0035cbe53c3!2sKannan%20Automation%20and%20Solutions!5e0!3m2!1sen!2sin!4v1704541029926!5m2!1sen!2sin"
              width="100%"
              height="300px"
              style={{ border: 0, marginLeft: "auto", marginRight: "auto" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-white">
          
          <div className="flex justify-between sm:w-[300px] pt-4 text-2xl">
            <FaFacebook />
            <FaInstagram />
            <FaTwitter />
            <FaTwitch />
            <FaGithub />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
